.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #24397F;
  color: #fff;
}
::selection {
  background: #24397F;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(36, 57, 127, 0.1);
  box-sizing: border-box;
}
.page {
  background: #fff;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000000;
  font-family: "erge-kanzlei", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 160rem;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  body {
    font-size: 1.125rem;
  }
}
@media (min-width: 61.875rem) {
  body {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
@media (min-width: 160rem) {
  body {
    border-left: 0.0625rem solid #d5d5d5;
    border-right: 0.0625rem solid #d5d5d5;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.4375rem + 0.9375
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.4375rem;
  color: #24397F;
  font-family: "erge-kanzlei", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.825em;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.4375rem + 0.9375
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h1 {
    font-size: 2.375rem;
  }
}
h2 {
  letter-spacing: calc((1.1875rem + 0.5625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #24397F;
  font-family: "erge-kanzlei", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.825em;
  padding-top: 0.825em;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(1.1875rem + 0.5625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h2 {
    font-size: 1.75rem;
  }
}
h3 {
  letter-spacing: calc((1.125rem + 0.25
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #24397F;
  font-family: "erge-kanzlei", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.825em;
  padding-top: 0.825em;
}
@media (min-width: 20rem) {
  h3 {
    font-size: calc(1.125rem + 0.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h3 {
    font-size: 1.375rem;
  }
}
h4 {
  letter-spacing: calc((1.0625rem + 0.3125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.0625rem;
  color: #24397F;
  font-family: "erge-kanzlei", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.825em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(1.0625rem + 0.3125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h4 {
    font-size: 1.375rem;
  }
}
h5 {
  letter-spacing: calc((1.0625rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.0625rem;
  color: #000000;
  font-family: "erge-kanzlei", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(1.0625rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h5 {
    font-size: 1.25rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.825em;
}
a {
  color: #24397F;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #000000;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(36, 57, 127, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #000000;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #24397F;
  display: inline-block;
  margin-bottom: 0.4125em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #000000;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(36, 57, 127, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 400;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.825em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.65em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.65em;
}
table {
  border-bottom: 0.0625rem solid #d1d1d1;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 400;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #d1d1d1;
  padding: 0.55em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #eaeaea;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.825em;
  padding: 0;
}
ol > li {
  padding-left: 1.65em;
  position: relative;
}
ol > li:before {
  color: #24397F;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 400;
  left: 0;
  line-height: 1.65;
  position: absolute;
  text-align: right;
  width: 1.2375em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  border-left: 0.0625rem solid #24397F;
  list-style: none;
  margin: 0 0 0.825em 0.33em;
  padding-left: 1.2375em;
}
ul > li {
  padding-left: 0.4125em;
}
ul ol,
ul ul {
  margin: 0;
}
main ul > li {
  padding-bottom: 0.2475em;
  padding-top: 0.2475em;
}
.defined_list_entry dl {
  border-bottom: 0.0625rem solid #d1d1d1;
  margin: 0.825em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.0625rem solid #d1d1d1;
  font-weight: 400;
  padding: 0.825em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.825em 0.825em 0.825em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.0625rem solid #d1d1d1;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.825em 0.55em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  border-left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.65em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.65em;
}
figure.floatright {
  float: right;
  margin-left: 1.65em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.4125em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
hr {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0.0625rem solid #24397F;
  display: inline-block;
  margin: 1.65em auto 1.155em 0;
  width: 38%;
}
.startpage hr {
  margin: 1.65em auto 2.145em 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #404040;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #404040;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #24397F;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #24397F;
  color: #fff;
  display: none;
  font-weight: 400;
  margin-right: 0.4125em;
  padding: 0 0.4125em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #000000;
  font-family: "erge-kanzlei", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.825em;
  padding: 0.825em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.4125em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0;
  box-shadow: 0 0 0 #d1d1d1 inset;
  color: #000000;
  margin: 0 0 1.1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #24397F;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #24397F;
  box-shadow: 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
  color: #000000;
}
.input:focus,
.textarea:focus {
  border-color: #24397F;
  box-shadow: 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
  color: #000000;
}
.input {
  height: 3.125rem;
  padding: 0 0.825em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.65;
  overflow: hidden;
  padding: 0.825em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.4125em 0.825em 0.825em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #24397F;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #24397F;
  box-shadow: 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
  color: #000000;
}
.control input:focus ~ .indicator {
  border-color: #24397F;
  box-shadow: 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
  color: #000000;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.825em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #000000;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #d1d1d1;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #d1d1d1 inset;
  color: #000000;
  cursor: pointer;
  display: block;
  margin: 0 0 1.1em;
  padding: 0 0.825em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #000000;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.825em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #24397F;
}
.select.error.focus {
  border-color: #24397F;
  box-shadow: 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
  color: #000000;
}
.select.focus {
  border-color: #24397F;
  box-shadow: 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
  color: #000000;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #000000;
  cursor: pointer;
  height: calc(3.125rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.select select:focus::-ms-value {
  background: none;
  color: #000000;
}
@-webkit-keyframes logo_animation_slogan {
  0% {
    opacity: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  75% {
    opacity: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes logo_animation_slogan {
  0% {
    opacity: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  75% {
    opacity: 0;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  80% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@-webkit-keyframes logo_animation_standard {
  0% {
    opacity: 0;
  }
  94% {
    opacity: 0;
  }
  96% {
    opacity: 1;
  }
}
@keyframes logo_animation_standard {
  0% {
    opacity: 0;
  }
  94% {
    opacity: 0;
  }
  96% {
    opacity: 1;
  }
}
@-webkit-keyframes logo_animation_1 {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
  94% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1%);
            transform: translateY(-1%);
  }
}
@keyframes logo_animation_1 {
  0% {
    opacity: 1;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
  94% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1%);
            transform: translateY(-1%);
  }
}
@-webkit-keyframes logo_animation_2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  1% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  98% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@keyframes logo_animation_2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  1% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  50% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  98% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@-webkit-keyframes header_image {
  0% {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes header_image {
  0% {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(36, 57, 127, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(36, 57, 127, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(36, 57, 127, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(36, 57, 127, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.65em;
  }
  * + [role="tablist"] {
    margin-top: 1.65em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #d1d1d1;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.4125em;
    min-width: 100%;
    padding: 0.55em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.825em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #24397F;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #24397F;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #24397F;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #24397F;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.65em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.475em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact .min_width {
    display: inline-block;
    min-width: 4.375rem;
  }
  .contact strong {
    min-width: 5.625rem;
  }
  .address_vcard_button {
    display: inline-block;
    margin: 0 0 0.825em 0;
  }
  .address_vcard_button:focus .icon,
  .address_vcard_button:hover .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .address_call_button {
    display: inline-block;
    margin: 0 0 0.825em 0;
  }
  .address_call_button:focus .icon,
  .address_call_button:hover .icon {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  aside[role="complementary"] {
    margin-top: 4.95em;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    display: inline-block;
    fill: #a0a0a0;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    width: 4.6875rem;
  }
  .breadcrumbs {
    display: none;
  }
  .button {
    border-radius: 0;
    font-size: 1rem;
    padding: 0 1.65em;
    background: #fff;
    border: 0.0625rem solid #24397F;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: calc(3.125rem - (0.0625rem * 2));
  }
  .button .icon {
    fill: #000000;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .button .text {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    font-weight: 400;
    padding: 0.55em 0;
    white-space: nowrap;
  }
  .button .icon + .text {
    padding-left: 0.55em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
    outline: 0;
    background: #24397F;
    border-color: #24397F;
    color: #fff;
    text-decoration: none;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #24397F;
    border-color: #24397F;
    color: #fff;
    text-decoration: none;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #24397F inset;
    background: #24397F;
    border: 0.0625rem solid #24397F;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: calc(3.125rem - (0.0625rem * 2));
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    font-weight: 400;
    padding: 0.55em 0;
    white-space: nowrap;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.55em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #24397F;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.4125em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #24397F;
    height: auto;
    left: 0.4125em;
    top: 0.4125em;
    width: auto;
  }
  .chart_organigram {
    display: none;
  }
  .google-visualization-orgchart-table {
    border-bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google-visualization-orgchart-table .node {
    background: #eaeaea;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 85%;
    padding: 0.4375rem;
    vertical-align: middle;
  }
  .google-visualization-orgchart-table .google-visualization-orgchart-linenode {
    border-color: #d1d1d1;
    border-top: 0;
  }
  .company_logo {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 0.66em;
    margin-right: auto;
    margin-top: 0.66em;
    text-decoration: none;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .company_logo .img_container {
    width: 12.5rem;
    display: block;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
  }
  .company_logo .company_name_text {
    font-size: 1.125rem;
    color: #000000;
    line-height: 1;
    margin-top: 0.8125rem;
    -webkit-transition: font-size 300ms ease-in-out;
    transition: font-size 300ms ease-in-out;
  }
  .company_logo:hover,
  .company_logo:focus {
    text-decoration: none;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  .startpage header .top .company_logo .img_container {
    display: block;
    position: relative;
  }
  .startpage header .top .company_logo .img_container .img_resize {
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: logo_animation_standard;
            animation-name: logo_animation_standard;
    opacity: 1;
  }
  .startpage header .top .company_logo .img_container .logo_animation_1,
  .startpage header .top .company_logo .img_container .logo_animation_2 {
    width: 12.5rem;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
  }
  .startpage header .top .company_logo .img_container .logo_animation_1 {
    -webkit-animation-name: logo_animation_1;
            animation-name: logo_animation_1;
    opacity: 0;
  }
  .startpage header .top .company_logo .img_container .logo_animation_2 {
    -webkit-animation-name: logo_animation_2;
            animation-name: logo_animation_2;
    opacity: 0;
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  .startpage header .top .company_logo .company_name_text {
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: logo_animation_slogan;
            animation-name: logo_animation_slogan;
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .startpage header .top .company_logo:hover,
  .startpage header .top .company_logo:focus {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #eaeaea;
    border-top: 0.125rem solid #d1d1d1;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.1em 0.825em 1.1em 1.65em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.825em;
    padding: 0 0.825em;
  }
  .cookie_banner .close > span {
    min-height: 3.125rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.33em;
  }
  .download_overview {
    margin-bottom: 0.825em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.4125em;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.825em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
    margin: 0 0 0 1.5675em;
    position: relative;
  }
  .dropdown_menu > ul > li:first-child {
    margin: 0;
  }
  .dropdown_menu > ul > li.hover ul,
  .dropdown_menu > ul > li:focus ul,
  .dropdown_menu > ul > li:hover ul {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  .dropdown_menu > ul > li > a {
    letter-spacing: calc((1rem + 0.375
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    -webkit-box-align: center;
            align-items: center;
    color: #000000;
    display: -webkit-box;
    display: flex;
    font-weight: 300;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 1;
    padding: 0.66em 0 0.495em 0;
    position: relative;
    text-decoration: none;
    -webkit-transition: color 300ms ease-in-out;
    transition: color 300ms ease-in-out;
    white-space: nowrap;
    z-index: 0;
  }
  .dropdown_menu > ul > li > a::after {
    background-color: #24397F;
    bottom: 0.375rem;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: -1;
  }
  .dropdown_menu > ul > li > a.active {
    color: #fff;
  }
  .dropdown_menu > ul > li > a.active::before {
    background-color: #24397F;
    bottom: 0;
    content: "";
    left: -0.495em;
    position: absolute;
    right: -0.495em;
    top: 0;
    z-index: -1;
  }
  .dropdown_menu > ul > li > a.active::after {
    background-color: #fff;
  }
  .dropdown_menu > ul > li > a:hover {
    outline: 0;
  }
  .dropdown_menu > ul > li > a:focus::after,
  .dropdown_menu > ul > li > a:hover::after {
    left: 0;
    opacity: 1;
    right: 0;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    border-bottom: 0.375rem solid #24397F;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    left: -0.99em;
    min-width: 23.75rem;
    opacity: 0;
    padding: 0.99em 1.98em;
    position: absolute;
    top: 5.94em;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: all 210ms ease-in-out;
    transition: all 210ms ease-in-out;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul li {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .dropdown_menu > ul > li ul a {
    color: #000000;
    display: inline-block;
    letter-spacing: calc((1rem + 0.25
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 1.6;
    margin: 0.0825em 0;
    padding: 0.165em 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    -webkit-transition: color 300ms ease-in-out;
    transition: color 300ms ease-in-out;
    z-index: 0;
  }
  .dropdown_menu > ul > li ul a::after {
    background-color: #24397F;
    bottom: 0.375rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: -1;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #fff;
  }
  .dropdown_menu > ul > li ul a.active::before {
    background-color: #24397F;
    bottom: 0;
    content: "";
    left: -0.495em;
    position: absolute;
    right: -0.495em;
    top: 0;
    z-index: -1;
  }
  .dropdown_menu > ul > li ul a.active::after {
    background-color: #fff;
  }
  .dropdown_menu > ul > li ul a:focus::after,
  .dropdown_menu > ul > li ul a:hover::after {
    opacity: 1;
    right: 0;
  }
  .dropdown_menu > ul > li ul a:hover {
    outline: 0;
  }
  .middle .fixed {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin-right: 0.9375rem;
    margin-top: 0.9375rem;
    padding-bottom: 0.33em;
  }
  .middle .fixed.transition {
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .middle .fixed .button_special,
  .middle .fixed .toggle_search_box a {
    -webkit-box-align: center;
            align-items: center;
    background-color: #24397F;
    color: #fff;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    margin-left: 0.495em;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .middle .fixed .button_special .icon,
  .middle .fixed .toggle_search_box a .icon {
    fill: #fff;
    height: 2.5rem;
    padding: 0.5rem;
    -webkit-transition: fill 300ms ease-in-out, width 300ms ease-in-out;
    transition: fill 300ms ease-in-out, width 300ms ease-in-out;
    width: 2.5rem;
  }
  .middle .fixed .button_special .text,
  .middle .fixed .toggle_search_box a .text {
    display: none;
  }
  .middle .fixed .button_special:focus,
  .middle .fixed .toggle_search_box a:focus,
  .middle .fixed .button_special:hover,
  .middle .fixed .toggle_search_box a:hover {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  footer {
    overflow-x: hidden;
    position: relative;
  }
  footer .container {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 2.64em auto 0;
    max-width: 115.625rem;
    width: 100%;
  }
  footer p {
    margin-bottom: 0;
  }
  footer .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  footer .contact .min_width {
    min-width: 1.5625rem;
  }
  footer .contact a {
    color: #000000;
    position: relative;
    text-decoration: none;
  }
  footer .contact a::after {
    background-color: #c9c9c9;
    bottom: 0.1875rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  footer .contact a:focus::after,
  footer .contact a:hover::after {
    left: 50%;
    opacity: 0;
    right: 50%;
  }
  footer .opening_times {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-top: 0.495em;
  }
  footer .opening_times .times .days {
    min-width: 5.625rem;
  }
  footer .related_links {
    background-color: #24397F;
    position: relative;
    z-index: 0;
  }
  footer .related_links::after {
    background-color: #24397F;
    bottom: 0;
    content: "";
    left: -62.4375rem;
    position: absolute;
    right: -62.4375rem;
    top: 0;
    z-index: -1;
  }
  footer .related_links ul {
    display: -webkit-box;
    display: flex;
    padding: 0.825em 0.99em;
  }
  footer .related_links li {
    border-left: 0.0625rem solid #fff;
    line-height: 1;
  }
  footer .related_links li a {
    letter-spacing: calc((0.9375rem + 0.0625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    color: #fff;
    margin: 0 0.99em;
    position: relative;
    text-decoration: none;
  }
  footer .related_links li a::after {
    background-color: #fff;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  footer .related_links li a:focus::after,
  footer .related_links li a:hover::after {
    left: 0;
    opacity: 1;
    right: 0;
  }
  footer .related_links li:first-child {
    border-left: 0;
    margin-left: 0;
  }
  footer .related_links li:last-child a {
    margin-right: 0;
  }
  footer .times_text {
    margin-bottom: 0.99em;
  }
  footer .times_text br {
    display: none;
  }
  footer .company_logo {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 0.66em;
    margin-right: auto;
    margin-top: 0.66em;
    text-decoration: none;
  }
  footer .company_logo .img_container {
    width: 12.5rem;
    display: block;
    -webkit-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
  }
  footer .company_logo .company_name_text {
    font-size: 1.125rem;
    color: #000000;
    line-height: 1;
    margin-top: 0.8125rem;
    -webkit-transition: font-size 300ms ease-in-out;
    transition: font-size 300ms ease-in-out;
  }
  footer .company_logo:hover,
  footer .company_logo:focus {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  footer .slogan {
    letter-spacing: calc((1.375rem + 1.25
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.375rem;
    color: #24397F;
    font-style: italic;
    line-height: 1;
    margin-right: 0.825em;
    margin-top: auto;
  }
  footer .wrapper_top {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.155em;
    width: 100%;
  }
  footer .wrapper_bottom {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: start;
            justify-content: flex-start;
    width: 100%;
  }
  footer .wrapper_bottom .left {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  footer .wrapper_bottom .right {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .form_disclaimer {
    background: #f0f1f6;
    border-radius: 0;
    color: #000000;
    font-size: 85%;
    margin-bottom: 0.825em;
    padding: 1.1em 1.65em;
    text-align: center;
  }
  .form_disclaimer a {
    display: block;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 3.125rem;
    margin: 0 0 1.1em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #24397F;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #d1d1d1 inset;
    color: #000000;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 3.125rem;
    line-height: calc(3.125rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 0.825em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.65em;
    z-index: 10;
    background: #fff;
    border: 0.0625rem solid #24397F;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: calc(3.125rem - (0.0625rem * 2));
  }
  .file .file_button .icon {
    fill: #000000;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    font-weight: 400;
    padding: 0.55em 0;
    white-space: nowrap;
  }
  .file .file_button .icon + .text {
    padding-left: 0.55em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
    background: #24397F;
    border-color: #24397F;
    color: #fff;
    text-decoration: none;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 3.125rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #24397F;
    border-color: #24397F;
    color: #fff;
    text-decoration: none;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #24397F inset;
    background: #24397F;
    border: 0.0625rem solid #24397F;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: calc(3.125rem - (0.0625rem * 2));
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    font-weight: 400;
    padding: 0.55em 0;
    white-space: nowrap;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.55em;
  }
  button {
    cursor: pointer;
    height: 3.125rem;
    margin: 0.825em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.65em;
    width: 100%;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 0.825em;
  }
  .google_maps .map {
    background: #eaeaea;
    border: 0.0625rem solid #e2e2e2;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.825em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #d1d1d1;
    border-color: #c4c4c4;
    color: #000000;
    text-decoration: none;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #000000;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
    z-index: 15;
    background: #2f4ba7;
    border-color: #1e306b;
    color: #fff;
    text-decoration: none;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 3.125rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: calc(3.125rem - (0.0625rem * 2));
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    font-weight: 400;
    padding: 0.55em 0;
    white-space: nowrap;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.55em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000000;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #24397F;
    font-size: 85%;
    margin-bottom: 0.825em;
    padding: 1.1em 1.65em;
  }
  .google_maps .direction {
    margin: 0.825em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.825em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #d1d1d1;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #d1d1d1;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.825em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.825em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.825em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .page {
    min-width: 20rem;
  }
  .container {
    margin: 0 auto;
    max-width: 87.5rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    position: relative;
  }
  header .top {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    display: -webkit-box;
    display: flex;
    top: 0;
    -webkit-transition: height 300ms ease-in-out;
    transition: height 300ms ease-in-out;
    width: 100%;
  }
  header .top .container {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    max-width: 115.625rem;
    width: 100%;
  }
  header .middle {
    position: relative;
  }
  header .middle .slider {
    line-height: 0;
    max-width: 100%;
    position: relative;
    z-index: 0;
  }
  header .middle .slider .slide {
    max-width: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 600ms ease-in-out;
    transition: opacity 600ms ease-in-out;
    z-index: 0;
  }
  header .middle .slider .slide img {
    max-width: 100%;
    position: relative;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  header .middle .slider .slide:first-child {
    position: relative;
  }
  header .middle .slider .slide.show {
    opacity: 1;
  }
  header .middle .slider .slide.show img {
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: header_image;
            animation-name: header_image;
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  header .middle .slider .slogan {
    background-color: rgba(255, 255, 255, 0.9);
    border-left: 1.125rem solid #24397F;
    bottom: 8%;
    padding: 0.99em 1.98em 0.99em 2.31em;
    position: absolute;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    z-index: 3;
  }
  header .middle .slider .slogan .title {
    letter-spacing: calc((1.375rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 15);
    font-size: 1.375rem;
    font-style: oblique;
    margin-bottom: 0.495em;
    padding-top: 0;
  }
  header .middle .slider .slogan p {
    letter-spacing: calc((1.125rem + 0
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.125rem;
    font-style: oblique;
    line-height: 1.65;
    margin-bottom: 0;
    margin-left: auto;
    text-align: right;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 1.65em 0;
  }
  .infolist_top_link a {
    color: #000000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
  }
  .infolist_top_link a::after {
    background-color: #000000;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .infolist_top_link a .icon {
    display: block;
    fill: #24397F;
    height: 2rem;
    margin: 0 auto;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 2rem;
  }
  .infolist_top_link a:focus,
  .infolist_top_link a:hover {
    text-decoration: none;
  }
  .infolist_top_link a:focus::after,
  .infolist_top_link a:hover::after {
    left: 20%;
    opacity: 1;
    right: 20%;
  }
  .infolist_top_link a:focus .icon,
  .infolist_top_link a:hover .icon {
    -webkit-transform: rotate(-90deg) scale(1.2);
            transform: rotate(-90deg) scale(1.2);
  }
  .disclaimer {
    background: #f0f1f6;
    border-radius: 0;
    color: #000000;
    font-size: 85%;
    margin-bottom: 0.825em;
    padding: 1.1em 1.65em;
    text-align: center;
  }
  .disclaimer a {
    display: block;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #eaeaea;
    border-radius: 0;
    color: #000000;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.65em;
    padding: 1.65em;
  }
  .infolist_download .infolist_title {
    font-weight: 400;
  }
  .infolist_download p {
    margin-right: 1.65em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #d1d1d1;
    margin-bottom: 0.825em;
    padding-top: 0.825em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .lexikon_liste a {
    color: #000000;
    text-decoration: underline;
  }
  .lexikon_liste a:hover,
  .lexikon_liste a:focus {
    text-decoration: none;
  }
  .infolist_overview .touch_link {
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.66em;
  }
  .infolist_overview .touch_link .icon {
    background-color: #24397F;
    fill: #fff;
    flex-shrink: 0;
    height: 3.125rem;
    margin-right: 1.155em;
    padding: 0.625rem;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 3.125rem;
  }
  .infolist_overview .touch_link .title {
    text-decoration: none;
  }
  .infolist_overview .touch_link:hover .icon,
  .infolist_overview .touch_link:focus .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  body {
    height: 100%;
    position: relative;
  }
  .main_wrapper {
    background-image: url("../images/footer_schraffur.jpg");
    background-position: bottom;
    background-repeat: repeat-x;
    overflow-x: hidden;
    padding: 1.65em 0 3.3em 0;
  }
  .startpage .small_width,
  .startpage h1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 57.8125rem;
  }
  .sitemap h2 a {
    position: relative;
    text-decoration: none;
  }
  .sitemap h2 a::after {
    background-color: #24397F;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .sitemap h2 a:focus::after,
  .sitemap h2 a:hover::after {
    opacity: 1;
    right: 0;
  }
  .sitemap ul {
    -webkit-column-gap: 0.9375rem;
            column-gap: 0.9375rem;
  }
  .sitemap ul a {
    color: #000000;
  }
  a {
    text-decoration: none;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
  a.touch_link {
    text-decoration: none;
  }
  a.touch_link:hover,
  a.touch_link:focus {
    text-decoration: none;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    margin-top: 1.32em;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 1.375rem;
    font-weight: 300;
    height: 3.125rem;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-right: 0.495em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #24397F;
    display: block;
    height: 0.25rem;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.6);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms;
    transition: opacity 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    overflow-x: hidden;
    padding: 1.65em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms;
    transition: opacity 240ms, transform 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    border-bottom: 0.0625rem solid #000000;
    padding: 0;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li:last-child {
    border-bottom: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #000000;
    display: block;
    font-size: 1.1875rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 3.125rem;
    padding: 0 0.66em;
    position: relative;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    background-color: #24397F;
    color: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    background-color: #d3d7e5;
    color: #000000;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
    margin-bottom: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li {
    padding: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #000000;
    display: inline-block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.33em 0.99em;
    text-decoration: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 100%;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    background-color: #d3d7e5;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    background-color: #d3d7e5;
    color: #000000;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.825em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.825em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.4125em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.6);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.65em;
  }
  .popup_close {
    background: #24397F;
    border-radius: 0 0 0 0;
    float: right;
    height: 2.5rem;
    margin-right: 0.0625rem;
    padding: 0.47142857em;
    text-align: center;
    width: 3.125rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 87.5rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.35);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.5rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background-color: #eaeaea;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(234, 234, 234, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.65em 3.3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.65em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.65em 0 0 0;
    text-align: left !important;
  }
  .open_street_map .form_disclaimer a {
    display: inline-block !important;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.65em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.825em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.65em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.0625rem solid #24397F;
    border-radius: 0;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 400;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #24397F;
    border: 0.0625rem solid #24397F;
    box-shadow: 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #24397F;
    border: 0.0625rem solid #24397F;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.825em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    text-align: left !important;
    background: #eaeaea;
    border-radius: 0;
    color: #000000;
    margin-bottom: 0.825em;
    padding: 1.1em 1.65em;
  }
  .opening_times .times .days {
    display: inline-block;
    min-width: 8.125rem;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #d1d1d1;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0;
    margin: 0;
    min-width: 3.125rem;
    padding: 0;
    width: 3.125rem;
    background: #fff;
    border: 0.0625rem solid #24397F;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: calc(3.125rem - (0.0625rem * 2));
  }
  form[role="search"] button .icon {
    fill: #000000;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1rem;
    font-weight: 400;
    padding: 0.55em 0;
    white-space: nowrap;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.55em;
  }
  form[role="search"] button[disabled] {
    background-color: #eaeaea;
    border-color: #dddddd;
    color: #808080;
  }
  form[role="search"] button[disabled] .icon {
    fill: #000000;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
    position: relative;
    z-index: 10;
    background: #24397F;
    border-color: #24397F;
    color: #fff;
    text-decoration: none;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(3.125rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0 #d1d1d1 inset;
    color: #000000;
    flex-basis: 100%;
    height: 3.125rem;
    margin: 0;
    padding: 0 0.825em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #24397F;
    box-shadow: 0 0 0.1875rem #24397F inset, 0 0 0 0.1875rem rgba(36, 57, 127, 0.2);
    color: #000000;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.825em;
    padding-top: 0.4125em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.825em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(255, 255, 255, 0.6);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    background: #24397F;
    border: 0.0625rem solid #24397F;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.7);
    fill: #fff;
    height: 2.75rem;
    padding: 0.25rem;
    position: absolute;
    right: 1.65em;
    top: 1.65em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #24397F;
    border-color: #24397F;
    outline: 0;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: auto;
    padding: 0 0.4125em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0 0.825em 0.825em 0;
  }
  .social_media_links a {
    border-radius: 0;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.facebook {
    background: #3664a2;
  }
  .social_media_links a.twitter {
    background: #1da1f2;
  }
  .social_media_links a.video {
    background: #24397F;
  }
  .steuernews_article_slider {
    display: none;
  }
  .steuernews_navigation {
    margin: 1.65em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.65;
    padding: 0 1.65em;
  }
  .steuernews_content [href*="thema"] {
    color: #000000;
  }
  .steuernews_preview {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 0.825em;
  }
  .steuernews_preview > .title {
    width: 100%;
  }
  .steuernews_preview .touch_link {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.65em;
    width: 100%;
  }
  .steuernews_preview .touch_link .image {
    border: 0.0625rem solid #e2e2e2;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    line-height: 0;
    margin: 0 auto 0.825em 0;
    overflow: hidden;
  }
  .steuernews_preview .touch_link .image img {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .steuernews_preview .touch_link .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .touch_link .teaser_text .title {
    letter-spacing: calc((1.0625rem + 0.3125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.0625rem;
    position: relative;
    text-decoration: none;
  }
  .steuernews_preview .touch_link .teaser_text .title::after {
    background-color: #24397F;
    bottom: -0.25rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .steuernews_preview .touch_link .video_play {
    position: relative;
  }
  .steuernews_preview .touch_link .video_play .icon {
    fill: #24397F;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview .touch_link:focus .image img,
  .steuernews_preview .touch_link:hover .image img {
    -webkit-transform: scale(1.07);
            transform: scale(1.07);
  }
  .steuernews_preview .touch_link:focus .teaser_text,
  .steuernews_preview .touch_link:hover .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .touch_link:focus .teaser_text .title::after,
  .steuernews_preview .touch_link:hover .teaser_text .title::after {
    left: 0;
    opacity: 1;
    right: 40%;
  }
  .steuernews_search {
    margin: 0 0 1.65em;
  }
  .steuernews_search .search {
    margin: 0 0 0.825em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_entity .news_image {
    border: 0.0625rem solid #e2e2e2;
    line-height: 0;
    margin-bottom: 1.155em;
  }
  .steuernews_video_overview {
    margin-bottom: 1.65em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.825em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #24397F;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_contact_person {
    background: #eaeaea;
    border-radius: 0;
    margin-bottom: 1.65em;
    padding: 1.65em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.65em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0;
    color: #24397F;
    display: block;
    line-height: 1.65;
    margin: 1em 0;
    padding: 0.825em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: #eaeaea;
    border-radius: 0;
    margin-bottom: 1.65em;
    padding: 1.65em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin: 1.65em 0 0.825em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.825em;
  }
  .steuernews_current_issue ul {
    border-left: 0.0625rem solid #24397F;
    list-style: none;
    margin: 0 0 0.825em 0.33em;
    padding-left: 1.2375em;
  }
  .steuernews_current_issue ul > li {
    padding-left: 0.4125em;
  }
  .steuernews_current_issue ul > li a {
    color: #000000;
    text-decoration: none;
  }
  .steuernews_current_issue ul > li a.active {
    color: #24397F;
    font-weight: 400;
  }
  .steuernews_current_issue ul > li a:hover,
  .steuernews_current_issue ul > li a:focus {
    text-decoration: underline;
  }
  .steuernews_issues .button {
    margin: 0 0.33em 0.495em 0;
  }
  .highlight_keyword {
    background: #24397F;
    color: #fff;
    outline: 0.125rem solid #24397F;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.825em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.65em 0.825em 0;
    max-width: 21.25rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.825em;
  }
  .team_overview .buttons .button {
    display: inline-block;
    margin: 0 0 0.825em 0;
  }
  .team_overview .buttons .button:focus .icon,
  .team_overview .buttons .button:hover .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .team_overview .buttons .call:focus .icon,
  .team_overview .buttons .call:hover .icon {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  .team_entry .photo {
    margin-bottom: 0.825em;
    max-width: 21.25rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.825em;
  }
  .startpage .teaser_chooser {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.65em;
    margin-top: 3.3em;
  }
  .startpage .teaser_chooser .teaser {
    margin-bottom: 1.65em;
    width: 100%;
  }
  .teaser_chooser .teaser {
    margin-bottom: 3.465em;
  }
  .teaser_chooser .teaser .wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.99em;
  }
  .teaser_chooser .teaser .wrapper .icon {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    fill: #24397F;
    height: 3.125rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.825em;
    width: 3.125rem;
  }
  .teaser_chooser .teaser .wrapper .title {
    display: -webkit-inline-box;
    display: inline-flex;
  }
  .teaser_chooser .teaser .button {
    margin-top: 1.155em;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #eaeaea;
    border: 0.0625rem solid #d1d1d1;
    border-radius: 0;
    color: #000000;
    font-size: 85%;
    font-weight: 300;
    line-height: 2.0625rem;
    padding: 0 0.825em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #eaeaea;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #d1d1d1;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .button .text {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active .text,
  .button.active .text {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .button:active .text,
  .button.active .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .chart_organigram {
    display: block;
    margin-bottom: 0.825em;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 68.75rem) {
  .company_logo {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo .img_container {
    width: calc(12.5rem + 2.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .company_logo .img_container {
    width: 15rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo .company_name_text {
    font-size: calc(1.125rem + 0.375
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .company_logo .company_name_text {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .company_logo .company_name_text {
    border-left: 0.0625rem solid #707070;
    margin-left: 0.66em;
    margin-top: 0.5rem;
    padding-left: 0.66em;
    padding-top: 0.9375rem;
  }
}
@media screen and (min-width: 87.5rem) {
  .company_logo .company_name_text {
    margin-left: 0.99em;
    padding-left: 0.99em;
  }
}
@media screen and (min-width: 20rem) {
  .startpage header .top .company_logo .img_container .logo_animation_1,
  .startpage header .top .company_logo .img_container .logo_animation_2 {
    width: calc(12.5rem + 2.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .startpage header .top .company_logo .img_container .logo_animation_1,
  .startpage header .top .company_logo .img_container .logo_animation_2 {
    width: 15rem;
  }
}
@media screen and (min-width: 68.75rem) {
  .scrolled_down header .top .company_logo .img_container {
    width: 7.5rem;
  }
  .scrolled_down header .top .company_logo .img_container .logo_animation_1,
  .scrolled_down header .top .company_logo .img_container .logo_animation_2 {
    width: 7.5rem;
  }
  .scrolled_down header .top .company_logo .company_name_text {
    font-size: 0.675rem;
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 20rem) {
  .scrolled_down header .top .company_logo .img_container {
    width: calc(7.5rem + 4.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 78.125rem) {
  .scrolled_down header .top .company_logo .img_container {
    width: 12rem;
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 20rem) {
  .scrolled_down header .top .company_logo .img_container .logo_animation_1,
  .scrolled_down header .top .company_logo .img_container .logo_animation_2 {
    width: calc(7.5rem + 4.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 78.125rem) {
  .scrolled_down header .top .company_logo .img_container .logo_animation_1,
  .scrolled_down header .top .company_logo .img_container .logo_animation_2 {
    width: 12rem;
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 20rem) {
  .scrolled_down header .top .company_logo .company_name_text {
    font-size: calc(0.6749999999999999rem + 0.5250000000000002
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 78.125rem) {
  .scrolled_down header .top .company_logo .company_name_text {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 78.125rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-top: 1.32em;
    -webkit-transition: margin-top 300ms ease-in-out;
    transition: margin-top 300ms ease-in-out;
  }
  .scrolled_down .dropdown_menu {
    margin-top: 0;
  }
}
@media screen and (min-width: 87.5rem) {
  .dropdown_menu > ul > li {
    margin: 0 0 0 2.805em;
  }
}
@media screen and (min-width: 87.5rem) {
  .dropdown_menu > ul > li:first-child {
    margin: 0;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    font-size: calc(1rem + 0.375
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 87.5rem) {
  .dropdown_menu > ul > li > a.active::before {
    left: -0.825em;
    right: -0.825em;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li ul a {
    font-size: calc(1rem + 0.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .dropdown_menu > ul > li ul a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 35rem) {
  .middle .fixed {
    align-content: stretch;
    -webkit-box-align: end;
            align-items: flex-end;
    bottom: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-right: 0;
    margin-top: 0;
    overflow-x: hidden;
    padding-bottom: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
    z-index: 220;
  }
}
@media screen and (min-width: 95.625rem) {
  .middle .fixed {
    bottom: 0;
    position: fixed;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: bottom 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: bottom 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, bottom 300ms ease-in-out;
    transition: transform 300ms ease-in-out, bottom 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
}
@media screen and (min-width: 35rem) {
  .middle .fixed .button_special,
  .middle .fixed .toggle_search_box a {
    height: 3.125rem;
    margin-bottom: 0.33em;
    margin-left: 0;
    min-width: 5.625rem;
    padding: 0 0.625rem 0 0;
    -webkit-transform: translateX(100%) translateX(-3.1rem);
            transform: translateX(100%) translateX(-3.1rem);
  }
}
@media screen and (min-width: 35rem) {
  .middle .fixed .button_special .icon,
  .middle .fixed .toggle_search_box a .icon {
    height: 3.125rem;
    padding: 0.75rem;
    width: 3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .middle .fixed .button_special .text,
  .middle .fixed .toggle_search_box a .text {
    color: #fff;
    display: block;
    font-size: 92%;
    font-weight: 400;
    padding: 0 0.625rem 0 0;
    text-decoration: none;
    white-space: nowrap;
  }
}
@media screen and (min-width: 35rem) {
  .middle .fixed .button_special:focus,
  .middle .fixed .toggle_search_box a:focus,
  .middle .fixed .button_special:hover,
  .middle .fixed .toggle_search_box a:hover {
    text-decoration: none;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .middle .fixed .button_special:focus .icon,
  .middle .fixed .toggle_search_box a:focus .icon,
  .middle .fixed .button_special:hover .icon,
  .middle .fixed .toggle_search_box a:hover .icon {
    width: 3.125rem;
  }
  .middle .fixed .button_special:focus .text,
  .middle .fixed .toggle_search_box a:focus .text,
  .middle .fixed .button_special:hover .text,
  .middle .fixed .toggle_search_box a:hover .text {
    text-decoration: none;
  }
}
@media screen and (min-width: 35rem) {
  .middle .fixed .button_special:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 29.9375rem) {
  .middle .fixed .facebook,
  .middle .fixed .toggle_search_box {
    display: none;
  }
}
@media screen and (min-width: 95.625rem) {
  .scrolled_down .middle .fixed {
    bottom: 50%;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
@media screen and (min-width: 61.875rem) {
  footer .container {
    margin-bottom: 5.61em;
  }
}
@media screen and (min-width: 87.5rem) {
  footer .container {
    margin: 2.64em auto;
  }
}
@media screen and (min-width: 30rem) {
  footer .contact_wrapper {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .opening_times {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin-top: 0;
  }
}
@media screen and (min-width: 98.125rem) {
  footer .opening_times {
    margin-left: 6.6em;
  }
}
@media screen and (min-width: 98.125rem) {
  footer .opening_times .times .days {
    min-width: 8.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .related_links {
    position: absolute;
    right: 0;
    overflow-x: hidden;
    padding-right: 1.98em;
    bottom: 1.32em;
  }
}
@media screen and (min-width: 87.5rem) {
  footer .related_links {
    padding-right: 0;
    margin-bottom: 0.25rem;
    position: relative;
    overflow-x: visible;
    bottom: 0;
  }
}
@media screen and (min-width: 78.125rem) {
  footer .related_links::after {
    left: 100%;
  }
}
@media screen and (min-width: 78.125rem) {
  footer .related_links ul {
    padding: 0.825em 0 0.825em 0.99em;
  }
}
@media screen and (max-width: 29.9375rem) {
  footer .related_links li:last-child {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  footer .related_links li a {
    font-size: calc(0.9375rem + 0.0625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  footer .related_links li a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .times_text {
    margin-bottom: 0;
    margin-left: 0.99em;
  }
}
@media screen and (min-width: 98.125rem) {
  footer .times_text {
    margin-left: 2.145em;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .times_text br {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .company_logo {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 78.125rem) {
  footer .company_logo {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  footer .company_logo .img_container {
    width: calc(12.5rem + 2.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  footer .company_logo .img_container {
    width: 15rem;
  }
}
@media screen and (min-width: 20rem) {
  footer .company_logo .company_name_text {
    font-size: calc(1.125rem + 0.375
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  footer .company_logo .company_name_text {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .company_logo .company_name_text {
    border-left: 0.0625rem solid #707070;
    margin-left: 0.66em;
    margin-top: 0.5rem;
    padding-left: 0.66em;
    padding-top: 0.9375rem;
  }
}
@media screen and (min-width: 87.5rem) {
  footer .company_logo .company_name_text {
    margin-left: 0.99em;
    padding-left: 0.99em;
  }
}
@media screen and (min-width: 20rem) {
  footer .slogan {
    font-size: calc(1.375rem + 1.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  footer .slogan {
    font-size: 2.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .slogan {
    margin-bottom: 0.2475em;
  }
}
@media screen and (min-width: 78.125rem) {
  footer .wrapper_top {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    margin-bottom: 2.145em;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .wrapper_bottom {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .wrapper_bottom .left {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .wrapper_bottom .right {
    margin-right: 0;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button .text {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .file .file_button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button .text {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .file [type="file"]:active ~ .file_button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio .text {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .google_maps .travel_mode_radio .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 0.9375
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .container {
    padding: 0 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (0.9375 / 2)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .row {
    margin: 0 -0.9375rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (0.9375 / 4)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .row.half_column_margin {
    margin: 0 -0.46875rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (0.9375 / 4)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.46875rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 68.75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 78.125rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (0.9375 / 2)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  [class*="span"] {
    padding: 0 0.9375rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 68.75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 78.125rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 68.75rem) {
  header {
    padding-top: 7.375rem;
  }
}
@media screen and (min-width: 68.75rem) {
  header .top {
    height: 7.375rem;
    max-width: 160rem;
    position: fixed;
    z-index: 3;
  }
}
@media screen and (max-width: 34.9375rem) {
  header .middle .slider .slogan {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  header .middle .slider .slogan {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@media screen and (min-width: 78.125rem) {
  header .middle .slider .slogan {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}
@media screen and (min-width: 115.625rem) {
  header .middle .slider .slogan {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@media screen and (min-width: 20rem) {
  header .middle .slider .slogan .title {
    font-size: calc(1.375rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  header .middle .slider .slogan .title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 20rem) {
  header .middle .slider .slogan p {
    font-size: calc(1.125rem + 0
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  header .middle .slider .slogan p {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 68.75rem) {
  .scrolled_down header .top {
    border-bottom: 0.0625rem solid #d5d5d5;
    height: 5.5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3.3em;
            column-gap: 3.3em;
  }
}
@media screen and (max-width: 68.6875rem) {
  .hideme_smaller_xxlarge {
    display: none;
  }
}
@media screen and (min-width: 35rem) {
  .main_wrapper {
    padding: 2.475em 0 3.795em 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .main_wrapper {
    padding: 3.3em 0 4.455em 0;
  }
}
@media screen and (min-width: 68.75rem) {
  .main_wrapper {
    padding: 4.125em 0 5.28em 0;
  }
}
@media screen and (min-width: 98.125rem) {
  .main_wrapper {
    padding: 4.95em 0 6.6em 0;
  }
}
@media screen and (min-width: 20rem) {
  .sitemap ul {
    -webkit-column-gap: calc(0.9375rem + 0.9375
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
            column-gap: calc(0.9375rem + 0.9375
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 61.875rem) {
  .sitemap ul {
    -webkit-column-count: 3;
            column-count: 3;
  }
}
@media screen and (min-width: 78.125rem) {
  .sitemap ul {
    -webkit-column-gap: 1.875rem;
            column-gap: 1.875rem;
  }
}
@media screen and (min-width: 68.75rem) {
  .more_margin_right {
    padding-right: 3.3em;
  }
}
@media screen and (max-width: 34.9375rem) {
  .toggle_offcanvas_menu {
    bottom: 0;
    left: 0.9375rem;
    margin-top: 0;
    position: absolute;
  }
}
@media screen and (min-width: 78.125rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 78.0625rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (max-width: 78.0625rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.65em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.65em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .text {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  form[role="search"] button .text {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider {
    display: block;
    height: 16.125rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    margin-bottom: 1.65em;
    z-index: 1;
  }
  .steuernews_article_slider::before {
    background-color: #eaeaea;
    bottom: 0;
    content: "";
    left: -62.4375rem;
    position: absolute;
    right: -62.4375rem;
    top: 0;
    z-index: 0;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 0;
  }
  .steuernews_article_slider .slide .image {
    min-width: 24.1875rem;
    width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    padding: 0 4.95em 0 3.3em;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.825em;
  }
  .steuernews_article_slider .slide_anchor:checked + .slide {
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }
  .steuernews_article_slider .control_slides {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background: #24397F;
    border: 0.0625rem solid #24397F;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 0;
    margin-top: -1.875rem;
    padding: 1.1em;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides svg {
    width: 1.125rem;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    background-color: #fff;
    fill: #24397F;
    outline: 0;
  }
  .steuernews_article_slider .prev_slide {
    left: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_preview .touch_link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .touch_link {
    width: 48%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 87.5rem) {
  .steuernews_preview .touch_link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_preview .touch_link .image {
    margin-bottom: auto;
    margin-right: 0.99em;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .touch_link .image {
    margin-bottom: 1.155em;
    margin-right: auto;
  }
}
@media screen and (min-width: 87.5rem) {
  .steuernews_preview .touch_link .image {
    margin-bottom: auto;
    margin-right: 1.32em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .touch_link .teaser_text .title {
    font-size: calc(1.0625rem + 0.3125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .steuernews_preview .touch_link .teaser_text .title {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .news_entity .news_image {
    float: right;
    margin-left: 1.65em;
    max-width: 15.625rem;
    margin-top: 1.65em;
  }
}
@media screen and (min-width: 61.875rem) {
  .news_entity .news_image {
    max-width: 100%;
  }
}
@media screen and (max-width: 29.9375rem) {
  .steuernews_issues {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 78.125rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 35rem) {
  .startpage .teaser_chooser {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .teaser_chooser {
    flex-wrap: nowrap;
    margin-top: 4.95em;
  }
}
@media screen and (min-width: 35rem) {
  .startpage .teaser_chooser .teaser {
    width: 42%;
  }
}
@media screen and (min-width: 61.875rem) {
  .startpage .teaser_chooser .teaser {
    margin-bottom: 0;
    width: 30%;
  }
}
@media print {
  hr {
    display: none!important;
  }
  .company_logo {
    display: block;
    margin-bottom: 0.825em;
    max-width: 11.25rem !important;
  }
  .company_logo .company_name_text,
  .company_logo .logo_animation_1,
  .company_logo .logo_animation_2 {
    display: none!important;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.65em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 400;
    margin-bottom: 0.825em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.65em;
  }
  .company_logo {
    display: block;
    max-width: 18.75rem;
  }
  .company_logo img {
    display: block;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.65em 0 0;
    padding: 0.825em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
